import { get } from 'lodash'
import { graphql } from 'gatsby'
import React from 'react'

import { Base, Footer, Navigation } from 'components/common'
import PrivacyPageTemplate from 'templates/privacy-page'

const getNode = obj => get(obj, 'edges.0.node', {})

export default function AboutPage({ data = {} }) {
  const { frontmatter: globals } = getNode(data.globals)
  const { html, frontmatter: content } = getNode(data.privacy)

  const {
    footer,
    navigation,
  } = globals

  const {
    heroHeading,
  } = content

  const { phoneNumber } = footer

  return (
    <Base>
      <Navigation {...navigation} phoneNumber={phoneNumber} />
      <PrivacyPageTemplate
        heroHeading={heroHeading}
        privacyPolicy={html}
      />
      <Footer {...footer} />
    </Base>
  )
}

export const pageQuery = graphql`
query PrivacyPageQuery {
  globals: allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "globals" } }}
  ) {
    edges {
      node {
        frontmatter {
          accreditations {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          footer {
            logo {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            emailAddress
            phoneNumber
          }
          navigation {
            logo {
              alternativeText
              image {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            pages {
              label
              path
            }
          }
        }
      }
    }
  }

  privacy: allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "privacy-page" } }}
  ) {
    edges {
      node {
        html
        frontmatter {
          heroHeading
        }
      }
    }
  }
}
`
