import React from 'react'
import { Fade, Flip } from 'react-reveal'

import {
  Content,
  Container,
  Heading,
  Section,
} from 'react-bulma-components'
import {
  Base,
  BasePreview,
  HtmlContent,
} from 'components/common'
import Hero from 'components/hero'


export default function PrivacyPageTemplate(props) {
  const {
    heroHeading,
    previewMode,
    privacyPolicy,
  } = props

  const BaseComponent = previewMode ? BasePreview : Base

  return (
    <BaseComponent>
      <Hero color="info">
        <Container
          className="padding-vertical-32"
          color="primary"
        >
          <Flip bottom>
            <Content size="large">
              <Heading size={1}>
                {heroHeading}
              </Heading>
            </Content>
          </Flip>
        </Container>
      </Hero>
      <Fade cascade>
        <Section>
          <Container className="padding-vertical-32">
            <Content size="medium">
              {privacyPolicy && <HtmlContent content={privacyPolicy} />}
            </Content>
          </Container>
        </Section>
      </Fade>
    </BaseComponent>
  )
}
